<template>
  <sm-categories-lists
    :breadcrumbs="breadcrumbs"
    :categories-group-name="categoriesGroupName"
    :list-name="categoriesGroupName"
  />
</template>

<script>
// components
import SmCategoriesLists from '@/components/views/SmCategoriesLists.vue';

export default {
  name: 'RequestsMain',

  components: {
    SmCategoriesLists,
  },

  data() {
    return {
      breadcrumbs: [{ text: 'Колл-центр' }],
      categoriesGroupName: 'callCenter',
    };
  },
};
</script>
